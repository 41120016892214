import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "./scss/index.scss";
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import lightGallery from "lightgallery";

import axios from "axios";
//Vue e Vue Validate
import Vue from "vue";
import {
    ValidationProvider,
    ValidationObserver,
    extend,
    localize,
} from "vee-validate";
import {
    required,
    email,
    numeric,
    max,
    alpha,
    alpha_spaces,
    regex,
} from "vee-validate/dist/rules";
import VueRecaptcha from 'vue-recaptcha';
extend("required", required);
extend("email", email);
extend("numeric", numeric);
extend("max", max);
extend("alpha", alpha);
let locale = document.getElementById("js-lang").value;
localize(locale, require(`vee-validate/dist/locale/${locale}.json`));


Vue.component('vue-recaptcha', VueRecaptcha);

//#region bootstrap navbar + hamburger + body scroll lock
var navbarMain = document.getElementById("navbarMain");
navbarMain.addEventListener("show.bs.collapse", function () {
  $(".navbar-toggler .hamburger").addClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
  /*  disableBodyScroll(scrollableNav); */
});
navbarMain.addEventListener("hidden.bs.collapse", function () {
  $(".navbar-toggler .hamburger").removeClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
  /*  enableBodyScroll(scrollableNav); */
});
//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
    /* innerSticker: ".topbar", */
    /*  bottomEnd: ".topbar", */
    /*  top: 60, */
  });
});
//#endregion
//#region topbar
var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("topbar").style.marginTop = "0";
    /*  $(".stickybar").toggleClass("show-topbar") */
  } else {
    document.getElementById("topbar").style.marginTop = "-60px";
    /*   $(".stickybar").removeClass("show-topbar"); */
  }
  prevScrollpos = currentScrollPos;
}
//#endregion

//#region offcavas menu mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});
$(".close-icon").on("click", function (e) {
  $("#navbarMain").removeClass("show");
  $("body").removeClass("offcanvas-active");
});
//#endregion

//#region slider
$(function () {
  $(".slider-lightbox").slick({
    dots: true,
    arrows: false,
    infinite: true,
    speed: 650,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    appendDots: $('.gallery-dots-container'),
    dotsClass: 'gallery-dots',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
});
//#endregion

//#region light gallery
if ($(".gallery__lightgallery").length) {
  let lg = document.getElementById("js-lightgallery");
  lg.addEventListener("lgAfterOpen", () => {
    let scrollableLg = document.querySelector(".lg-container");
    /* disableBodyScroll(scrollableLg); */
  });
  lg.addEventListener("lgAfterClose", () => {
    let scrollableLg = document.querySelector(".lg-container");
    /* enableBodyScroll(scrollableLg); */
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
      download: true,
    },
  });
}
//#endregion
//#region fade homepage
$(document).ready(function () {
  $(window).on("scroll", function () {
    $('.animated').each(function (i) {
      var bottom_of_object = $(this).offset().top + $(this).outerHeight();
      var bottom_of_window = $(window).scrollTop() + $(window).height();
      if (bottom_of_window > bottom_of_object) {

        $(this).animate({ 'opacity': '1' }, 800);
      }
    });
  });
})

//#endregion

//#region Form Contatti

const elContactsForm = document.getElementById("ContactsForm");
if (elContactsForm) {
    const ContactsForm = new Vue({
        el: "#ContactsForm",
        data() {
            return {
                formData: {
                    nome: "",
                    cognome: "",
                    indirizzo: "",
                    email: "",
                    telefono: "",
                    domanda: "",
                    privacy: ""
                },
                sent: false,
                sending: false,
            };
        },
        components: { ValidationProvider, ValidationObserver },
        methods: {
            onSubmit() {
                this.sending = true;
                var formData = new FormData();
                for (let key in this.formData) {
                    formData.append(key, this.formData[key]);
                }
                axios
                    .post(window.location, formData)
                    .then((res) => {
                        //this.$refs.quoteFormAnchor.scrollIntoView(false);
                        this.sent = true;
                        this.sending = false;

                        window.scrollTo(0, elSignUpForm);
                    })
                    .catch((err) => {
                        this.sending = false;
                    });
            }
        },
        created() {
            let qs = new URLSearchParams(window.location.search);
            //this.formData.domanda = qs.get("domanda");
            // to test faster
            /* this.formData.name = "";
            this.formData.surname = "";
            this.formData.city = "";
            this.formData.country = "";
            this.formData.email = "";
            this.formData.phone = "";
            this.formData.message = "";
            this.formData.privacy = ""; */
            // end
        }
    });
}

//#endregion 

//#region Form Referti

const elRefertiForm = document.getElementById("RefertiForm");
if (elRefertiForm) {
    const RefertiForm = new Vue({
        el: "#RefertiForm",
        data() {
            return {
                formData: {
                    nome: "",
                    email: "",
                    daDefinire: "",
                    telefono: "",
                    privacy: ""
                },
                sent: false,
                sending: false,
            };
        },
        components: { ValidationProvider, ValidationObserver },
        methods: {
            onSubmit() {
                this.sending = true;
                var formData = new FormData();
                for (let key in this.formData) {
                    formData.append(key, this.formData[key]);
                }
                axios
                    .post(window.location, formData)
                    .then((res) => {
                        //this.$refs.quoteFormAnchor.scrollIntoView(false);
                        this.sent = true;
                        this.sending = false;

                        window.scrollTo(0, elSignUpForm);
                    })
                    .catch((err) => {
                        this.sending = false;
                    });
            }
        },
        created() {
            let qs = new URLSearchParams(window.location.search);
            //this.formData.domanda = qs.get("domanda");
            // to test faster
            /* this.formData.name = "";
            this.formData.surname = "";
            this.formData.city = "";
            this.formData.country = "";
            this.formData.email = "";
            this.formData.phone = "";
            this.formData.message = "";
            this.formData.privacy = ""; */
            // end
        }
    });
}

//#endregion 




